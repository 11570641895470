<template>
  <div>
    <title>SIM INVENTORY ~ FINISH GOOD OUT DETAIL DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Finish Good Out Detail Data Lists
        <br />
        <h4>The following is a list of finish good out detail data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Finish Good Out Detail Data <b style="color:red">{{ nope }}</b> </h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-2">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Out Number</th>
                      <th>Order Buyer</th>
                      <th>Product Number</th>
                      <th>Qty</th>
                      <th>Currency</th>
                      <th>FOB</th>
                      <th>CMT</th>
                      <th>Carton</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(classdata,index) in list_paged" :key="classdata.ID">
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>

                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_pengeluaran }}</td>
                      <td>{{ classdata.buyer_order }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.quantity }} &nbsp; {{classdata.unit}}</td>
                      <td>{{ classdata.currency_unit }}</td>
                      <td>{{ classdata.nilai_fob }}</td>
                      <td>{{ classdata.nilai_cmt }}</td>
                      <td>{{ classdata.jml_ctn }}</td>
                      <td>
                        <button
                          title="Delete Finish Good Out Detail"
                          @click="deleteData(classdata.ID)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                     <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Out Number</th>
                      <th>Order Buyer</th>
                      <th>Product Number</th>
                      <th>Qty</th>
                      <th>Currency</th>
                      <th>FOB</th>
                      <th>CMT</th>
                      <th>Carton</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="10" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
  
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      tipebc: [
        { value: "25", id: 1 },
        { value: "261", id: 2 },
        { value: "27", id: 3 }
      ],
      loading: false,
      isExist: true,
      isEdit: false,
      isShow: false,
      namesearch: "",
      nope: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.nope = this.$route.params.nomorpengeluaran;
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "fg_outgoing_d/getalldatafg_outgoing_d?length=" + this.pageSize + "&page=" + this.page +"&nomor_pengeluaran=" + this.$route.params.nomorpengeluaran
      console.log(urlAPIget)
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel = this.$apiurl + "fg_outgoing_d/hapusfg_outgoing_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              })
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
